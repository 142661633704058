export default {
    namespaced: true,

    
    state:() => ({
        isSidebarOpen: true,
        isSharedState: false,
        ordersCount: null,
        titlesCount: null
    }),
    
    mutations:{
        
        SET_SIDEBAR_OPEN(state){
            state.isSidebarOpen=true;
        },
        SET_SIDEBAR_CLOSE(state){
            state.isSidebarOpen=false;
        },

    
      }
  }