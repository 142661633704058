export default {
    namespaced: true,


    state: () => ({
        userData: {},
        userRoles: [], // isLogged:false,
        isUser: false,
        isSuperAdmin: false,
        isAdmin: false,
        isEditor: false,
        isTranslator: false,
        isProofreader: false,
        isAccounting: false,
        isAccountingJr: false,
        isEditorialStaff: false,
        emailValidationExpired: false,
        emailValidationError: false,
        EmailValidationErrorMessage: '',
        isLoggedAsUser: false,
        fullUserRoles: [],
        userWebsites: []
    }), mutations: {
        SET_ROLES(state, roles) {
            if (!roles) {
                return;
            }
            state.userRoles = roles.map(singleRole => singleRole.name);
            state.fullUserRoles = roles;
        }, SET_USER_ROLES(state) {
            state.isSuperAdmin = false;
            state.isAdmin = false;
            state.isEditor = false;
            state.isTranslator = false;
            state.isProofreader = false;
            state.isAccounting = false;
            state.isAccountingJr = false;
            state.isEditorialStaff = false;
            state.isUser = false;

            if (!state.userRoles) {
                return;
            }
            if (state.userRoles.indexOf("super-admin") > -1) {
                state.isSuperAdmin = true;
                state.isAdmin = true;
                state.isEditor = true;
                state.isTranslator = true;
                state.isProofreader = true;
                state.isAccounting = true;
                state.isAccountingJr = true;
                state.isEditorialStaff = true;
                state.isUser = true;
                return;
            }

            if (state.userRoles.indexOf("admin") > -1) {
                state.isAdmin = true;
                state.isEditor = true;
                state.isTranslator = true;
                state.isProofreader = true;
                state.isEditorialStaff = true;
                state.isUser = true;
            }

            if (state.userRoles.indexOf("editor") > -1) {
                const editorRole = state.fullUserRoles.find(role => role.name === 'editor');
                if (editorRole && 'role_languages' in editorRole) {
                    state.isEditor = true;
                }
            }

            if (state.userRoles.indexOf("translator") > -1) {
                const translatorRole = state.fullUserRoles.find(role => role.name === 'translator');
                if (translatorRole && 'role_languages' in translatorRole) {
                    state.isTranslator = true;
                }
            }

            if (state.userRoles.indexOf("proofreader") > -1) {
                const proofreaderRole = state.fullUserRoles.find(role => role.name === 'proofreader');
                if (proofreaderRole && 'role_languages' in proofreaderRole) {
                    state.isProofreader = true;
                }
            }

            if (state.userRoles.indexOf("accounting") > -1) {
                state.isAccounting = true;
            }

            if (state.userRoles.indexOf("accounting-jr") > -1) {
                state.isAccountingJr = true;
            }

            if (state.userRoles.indexOf("editorial-staff") > -1) {
                state.isEditorialStaff = true;
            }

            if (state.userRoles.indexOf("user") > -1) {
                state.isUser = true;
            }


        }, SET_IS_LOGGED_AS_USER(state) {
            if (localStorage.getItem('mainToken') === null || localStorage.getItem('mainToken') === 'null' || localStorage.getItem('mainToken') === undefined || localStorage.getItem('mainToken') === 'undefined' || localStorage.getItem('mainToken') === '') {
                return;
            }
            state.isLoggedAsUser = true;
        }
    }, actions: {

        async getUserInfo(context, {withFiscal}) {

            if (!localStorage.getItem('requestCache') || Date.now() > localStorage.getItem('requestCache')) {
                localStorage.setItem('requestCache', Date.now() + 30000);

                let endpoint = '';
                if (withFiscal) {
                    endpoint = `${process.env.VUE_APP_BASE_SERVER_URL}/${process.env.VUE_APP_API_VERSION}/profile/me?with_fiscal_data`;
                } else {
                    endpoint = `${process.env.VUE_APP_BASE_SERVER_URL}/${process.env.VUE_APP_API_VERSION}/profile/me`;
                }
                await this.$axios.get(endpoint).then(res => {
                    console.log(res)
                    if (res.data.data.user.terms_accepted_at === null || res.data.data.terms_updated_at > res.data.data.user.terms_accepted_at) {
                        this.$router.push({name: 'user-terms-and-conditions'});
                        return
                    }
                    if (res.data.data.user.email_verified_at) {
                        context.state.userData = res.data.data.user;
                        context.state.userWebsites = res.data.data.websites_auth_to_publish;
                        context.commit('SET_ROLES', res.data.data.user.roles ? res.data.data.user.roles : null);
                        context.commit('SET_USER_ROLES');
                        context.commit('SET_IS_LOGGED_AS_USER');
                    } else {
                        this.$router.push({name: 'email-verification-required'});
                        return
                    }

                    if (!res.data.data.user.birth_city || !res.data.data.user.birth_country || !res.data.data.user.birth_date || res.data.data.user.gender === null || !res.data.data.user.native_language || !res.data.data.user.phone || !res.data.data.user.residence_address || !res.data.data.user.residence_city || !res.data.data.user.residence_country || !res.data.data.user.residence_zip) {
                        this.$router.push({name: 'register-data'});
                        return
                    }
                }).catch(err => {
                    if (err.response.status === 401) {
                        this.$router.push({name: 'login'})
                    }
                });


                // Fetch the signed contracts
                endpoint = `${process.env.VUE_APP_BASE_SERVER_URL}/${process.env.VUE_APP_API_VERSION}/contracts/signed`;
                await this.$axios.get(endpoint).then(response => {
                    const signedContracts = response.data.data.signed_contracts;

                    // Find the contracts that have been updated after the terms_accepted_at date
                    const updatedContracts = signedContracts.filter((signed) => signed.updated_at < signed.contract.updated_at);

                    if (updatedContracts.length > 0) {
                        let signed = updatedContracts[0];
                        let routeParam = {
                            path: `/contracts-update/${signed.id}/${signed.contract.role_id}/${signed.contract.language}`,
                        }
                       this.$router.push(routeParam);
                    }
                }).catch(err => {
                    if (err.response.status === 401) {
                        this.$router.push({name: 'login'})
                    }
                });

            }


        },

        resendEmailVerification() {

            this.$axios.post(`${process.env.VUE_APP_BASE_SERVER_URL}/${process.env.VUE_APP_API_VERSION}/profile/email/send-verification`, {}).then(res => {
                if (res.status === 200) {
                    this.$router.push({name: "thank-you"});
                }
            })

        },

        verifyEmailAddress({state}, payload) {

            if (payload.expires * 1000 < Date.now()) {
                state.emailValidationExpired = true;
            } else {
                state.emailValidationExpired = false;
                this.$axios.get(`${process.env.VUE_APP_BASE_SERVER_URL}/${process.env.VUE_APP_API_VERSION}/profile/email/verify/${payload.userId}/${payload.emailHash}?expires=${payload.expires}&signature=${payload.signature}`).then(res => {
                    if (res.status === 200) {
                        this.$router.push({name: 'register-data'})
                    }
                }).catch(error => {
                    state.emailValidationError = true;
                    state.EmailValidationErrorMessage = error.response.data.error.message;
                })
            }

        },

        updateFiscalData({state}, payload) {
            console.log(state, payload)
        }

    }
}