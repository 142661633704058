import { loadLanguageAsync } from "@/i18n/i18n";
export default {
    namespaced: true,

    
    state:() => ({
        locales: ["it", "en", "fr", "es", "br","de"],
        languages: [
            {
                code:'it-IT',
                name:'Italian'
            },
            {
                code:'en-GB',
                name:'UK English'
            },
            {
                code:'es-ES',
                name:'Spanish'
            },
            {
                code:'fr-FR',
                name:'French'
            },
            {
                code:'en-US',
                name:'US English'
            },
            {
                code:'pt-BR',
                name:'Portughese'
            },
            {
                code:'de-DE',
                name:'German'
            }
        ],
        dropdown:false
    }),
    
    mutations:{
        
        CHANGE_LOCALE(state,payload){
            loadLanguageAsync(payload.lang)
            document.getElementById('locales-dropdown').style.opacity=0;
            document.getElementById('locales-dropdown').style.pointerEvents='none';
            state.dropdown=false;
        },
        OPEN_DROPDOWN(state){
            document.getElementById('locales-dropdown').style.opacity=1;
            document.getElementById('locales-dropdown').style.pointerEvents='all';
            state.dropdown=true;
        },
        CLOSE_DROPDOWN(state){
            document.getElementById('locales-dropdown').style.opacity=0;
            document.getElementById('locales-dropdown').style.pointerEvents='none';
            state.dropdown=false;
        }
    
      },

    actions:{
        changeLanguage (context, payload) {
            context.commit('CHANGE_LOCALE' , payload)
          }
    }
  }