export default {
    namespaced: true,

    state:() => ({
        theme: localStorage.getItem("theme") === "dark" || localStorage.getItem("theme") === "light" ? localStorage.getItem("theme") : "light"
      }),
    
    mutations:{
     
        FETCH_THEME(state){
            state.theme = localStorage.getItem("theme") === "dark" || localStorage.getItem("theme") === "light" ? localStorage.getItem("theme") : "light"; 
            document.documentElement.setAttribute("data-theme", state.theme);
        },
      
        TOGGLE_THEME(state){
            state.theme = state.theme === 'dark' ? 'light' : 'dark';
            document.documentElement.setAttribute('data-theme', state.theme);
            localStorage.setItem('theme', state.theme);
        }
    
      }
  }