export default {
    namespaced: true,

    
    state:() => ({
        categories: [
            {
                code: 1,
                name: 'Communication'
            },
            {
                code: 2,
                name: 'Document'
            },
            {
                code: 3,
                name: 'Protected'
            },
            {
                code: 4,
                name: 'Ticket'
            },
            {
                code: 5,
                name: 'User'
            },
            {
                code: 6,
                name:'Exam'
            },
            {
                code: 7,
                name: 'All'
            }
        ],
    })
  }