export default {
    namespaced: true,

    state:() => ({
        roles: [
            {
                id: 1,
                name:'Editor'
            },
            {
                id: 2,
                name:'Proofreader'
            },
            {
                id: 3,
                name: 'Translator'
            }
        ],
    }),

    getters: {
        getRoleNameById: (state) => (id) => {
          return state.roles.find(role => role.id === id).name
        }
      }
  }