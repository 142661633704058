<style src="./assets/Stylesheets/Shared/main.css"></style>

<template>
    <router-view/>
</template>

<script>
    import { loadLanguageAsync } from './i18n/i18n';
export default {
    data() {
        return {
            theme: this.$store.state.themeModule.theme
        };
    },
    mounted() {
        this.$store.commit('themeModule/FETCH_THEME');
        loadLanguageAsync(localStorage.getItem('lang'));

        if (process.env.VUE_APP_ENVIRONMENT !== 'development') {
            console.log = function() {};
        }
    },
    created(){
        if(localStorage.getItem('requestCache') && localStorage.getItem('requestCache') !== ''){
            localStorage.setItem('requestCache', Date.now()); 
        }

        if(localStorage.getItem('ordersCountCache') && localStorage.getItem('ordersCountCache') !== ''){
            localStorage.setItem('ordersCountCache', Date.now()); 
        }
    }
}
</script>
