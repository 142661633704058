export default {
    namespaced: true,

    
    state:() => ({
        currencies: [
            {
                code:1,
                name:'EUR',
                symbol:'€'
            },
            {
                code:2,
                name:'USD',
                symbol:'$'
            },
            {
                code:3,
                name:'USD',
                symbol:'$'
            }
        ],
    })
  }