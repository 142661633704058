import { createApp } from 'vue'
import App from './App.vue'
import { store } from './store/index'
import router from './router'
import {i18n} from './i18n/i18n'
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { $axios , axios } from '@/httpHelper/axios-instances.js';
import { 
    faEye,
    faEyeSlash,
    faMoneyBill1,
    faAddressCard,
    faNewspaper,
    faCircleQuestion,
    faCircleXmark,
    faPenToSquare,
    faClock,
    faImage,
    faPaperPlane,
    faShareFromSquare,
    faHeart,
    faCopy,
    faFilePdf
} from '@fortawesome/free-regular-svg-icons'
import { 
    faChevronDown,
    faChevronRight,
    faChevronLeft,
    faChartLine,
    faTags,
    faUser,
    faUsers,
    faFlag,
    faWallet,
    faClipboardList,
    faHeadphones,
    faChartColumn,
    faGear,
    faGlobe,
    faCircleNotch,
    faBars,
    faXmark,
    faEllipsis,
    faChartSimple,
    faKey,
    faThumbsUp,
    faBell,
    faDroplet,
    faLanguage,
    faBold,
    faItalic,
    faUnderline,
    faListOl,
    faList,
    faAlignCenter,
    faAlignLeft,
    faAlignRight,
    faAlignJustify,
    faRotateLeft,
    faRotateRight,
    faGripLines,
    faQuoteLeft,
    faLink,
    faLinkSlash,
    faCode,
    faMinus,
    faBroom,
    faStrikethrough,
    faPlus,
    faArrowRight,
    faGripVertical,
    faBook,
    faCheck,
    faTicket,
    faTrash,
    faChevronUp,
    faBug,
    faRepeat,
    faDownload
} from '@fortawesome/free-solid-svg-icons'



library.add(
    faEye,
    faEyeSlash,
    faChevronDown,
    faChevronUp,
    faChevronRight,
    faChevronLeft,
    faChartLine,
    faMoneyBill1,
    faAddressCard,
    faNewspaper,
    faTags,
    faUser,
    faUsers,
    faFlag,
    faWallet,
    faClipboardList,
    faHeadphones,
    faChartColumn,
    faGear,
    faCircleQuestion,
    faGlobe,
    faCircleXmark,
    faCircleNotch,
    faBars,
    faXmark,
    faEllipsis,
    faPenToSquare,
    faChartSimple,
    faKey,
    faThumbsUp,
    faBell,
    faDroplet,
    faLanguage,
    faBold,
    faItalic,
    faUnderline,
    faListOl,
    faList,
    faAlignCenter,
    faAlignLeft,
    faAlignRight,
    faAlignJustify,
    faRotateLeft,
    faRotateRight,
    faGripLines,
    faQuoteLeft,
    faLink,
    faLinkSlash,
    faCode,
    faMinus,
    faBroom,
    faStrikethrough,
    faPlus,
    faArrowRight,
    faClock,
    faImage,
    faGripVertical,
    faBook,
    faCheck,
    faPaperPlane,
    faShareFromSquare,
    faTicket,
    faHeart,
    faTrash,
    faCopy,
    faBug,
    faRepeat,
    faDownload,
    faFilePdf
);


const app = createApp(App)
.use(store)
.use(i18n)
.use(router)
.use(Toast, {
    position: POSITION.BOTTOM_RIGHT
})
.component('FontAwesomeIcon', FontAwesomeIcon);

app.config.globalProperties.$axios = $axios; //this instance has specified interceptors for interaction with Creators-api 
app.config.globalProperties.axios = axios; //this instance is fully customizable for interaction with third-party services

app.mount('#app');


