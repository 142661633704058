export default {
    namespaced: true,

    
    state:() => ({
        passwordShowing:false,
        passwordConfShowing:false,
        serverError:false,
        emailError:false,
        passwordError:false,
        nameError:false,
        lastNameError:false,
        passwordConfError: false,
        checkboxError: false,
        isLoading:false,

        firstName:'',
        lastName:'',
        email:'',
        password:'',
        passwordConfirmation:'',
        checkboxAcceptance:'',

        errorMessage:''
    }),
    
    mutations:{
        
        SHOW_PASSWORD(state,payload){
            const pw = document.getElementById(payload.id);
            if(pw.type === 'password'){
                pw.type='text';
                payload.id === 'password' ? state.passwordShowing=true : payload.id === 'repeatPassword' ? state.passwordConfShowing=true : ''
            }else{
                pw.type='password';
                payload.id === 'password' ? state.passwordShowing=false : payload.id === 'repeatPassword' ? state.passwordConfShowing=false : ''
            }
        },

        VALIDATE_LOGIN_INPUTS(state,payload){
            
            if(state.email === ''){
                state.emailError=true;
                state.passwordError=false;
                state.errorMessage=payload.t('auth.register.email_error_message_1');
                return;
            }else if(!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(state.email)){
                state.emailError=true;
                state.passwordError=false;
                state.errorMessage=payload.t('auth.register.email_error_message_2');
                return;
            }else if(state.email.length>=40){
                state.emailError=true;
                state.passwordError=false;
                state.errorMessage=payload.t('auth.register.email_error_message_3');
                return;
            }else{
                this.emailError=false
            }


            if(state.password === ''){
                state.passwordError=true;
                state.emailError=false;
                state.errorMessage=payload.t('auth.register.password_error_message_1');
                return;
            }else if(state.password.length<8){
                state.passwordError=true;
                state.emailError=false;
                state.errorMessage=payload.t('auth.register.password_error_message_2');
                return;
            }else if(state.password.length>=25){
                state.passwordError=true;
                state.emailError=false;
                state.errorMessage=payload.t('auth.register.password_error_message_3');
                return;
            }else{
                state.passwordError=false;
            }

            this.dispatch('authForms/loginUser')

        },

        VALIDATE_REGISTER_INPUTS(state,payload){
            if(state.firstName === ''){
                state.nameError=true;
                state.lastNameError=false;
                state.emailError=false;
                state.passwordError=false;
                state.passwordConfError=false;
                state.checkboxError=false;
                state.errorMessage=payload.t('auth.register.first_name_error_message_1');
                return;
            }else if(state.firstName.length>=20){
                state.nameError=true;
                state.lastNameError=false;
                state.emailError=false;
                state.passwordError=false;
                state.passwordConfError=false;
                state.checkboxError=false;
                state.errorMessage=payload.t('auth.register.first_name_error_message_2');
                return;
            }else{
                state.nameError=false;
            }

            if(state.lastName === ''){
                state.lastNameError=true;
                state.nameError=false;
                state.emailError=false;
                state.passwordError=false;
                state.passwordConfError=false;
                state.checkboxError=false;
                state.errorMessage=payload.t('auth.register.last_name_error_message_1');
                return;
            }else if(state.lastName.length>=20){
                state.lastNameError=true;
                state.nameError=false;
                state.emailError=false;
                state.passwordError=false;
                state.passwordConfError=false;
                state.checkboxError=false;
                state.errorMessage=payload.t('auth.register.last_name_error_message_2');
                return;
            }else{
                state.lastNameError=false;
            }

            if(state.email === ''){
                state.emailError=true;
                state.lastNameError=false;
                state.nameError=false;
                state.passwordError=false;
                state.passwordConfError=false;
                state.checkboxError=false;
                state.errorMessage=payload.t('auth.register.email_error_message_1');
                return;
            }else if(!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(state.email)){
                state.emailError=true;
                state.lastNameError=false;
                state.nameError=false;
                state.passwordError=false;
                state.passwordConfError=false;
                state.checkboxError=false;
                state.errorMessage=payload.t('auth.register.email_error_message_2');
                return;
            }else if(state.email.length>=40){
                state.emailError=true;
                state.nameError=false;
                state.lastNameError=false;
                state.passwordError=false;
                state.passwordConfError=false;
                state.checkboxError=false;
                state.errorMessage=payload.t('auth.register.email_error_message_3');
                return;
            }else{
                state.emailError=false;

            }

            if(state.password === ''){
                state.passwordError=true;
                state.emailError=false;
                state.lastNameError=false;
                state.nameError=false;
                state.passwordConfError=false;
                state.checkboxError=false;
                state.errorMessage=payload.t('auth.register.password_error_message_1');
                return;
            }else if(state.password.length<8){
                state.passwordError=true;
                state.emailError=false;
                state.lastNameError=false;
                state.nameError=false;
                state.passwordConfError=false;
                state.checkboxError=false;
                state.errorMessage=payload.t('auth.register.password_error_message_2');
                return;
            }else if(state.password.length>=25){
                state.passwordError=true;
                state.emailError=false;
                state.lastNameError=false;
                state.nameError=false;
                state.passwordConfError=false;
                state.checkboxError=false;
                state.errorMessage=payload.t('auth.register.password_error_message_3');
                return;
            }else{
                state.passwordError=false;

            }

            if(state.password != state.passwordConfirmation){
                state.passwordConfError=true;
                state.emailError=false;
                state.lastNameError=false;
                state.nameError=false;
                state.passwordError=false;
                state.checkboxError=false;
                state.errorMessage=payload.t('auth.register.password_conf_error_message_1');
                return;
            }else{
                state.passwordConfError=false;
            }
            if(state.checkboxAcceptance === ''){
                state.checkboxError=true;
                state.emailError=false;
                state.lastNameError=false;
                state.nameError=false;
                state.passwordError=false;
                state.passwordConfError=false;
                state.errorMessage=payload.t('auth.register.check_conf_error_message_1');
                return;
            }else{
                state.checkboxError=false;
            }

            this.dispatch('authForms/registerUser');


        },

        VALIDATE_RECOVER_PASSWORD_INPUT(state,payload){
            if(state.email === ''){
                state.emailError=true;
                state.errorMessage=payload.t('auth.register.email_error_message_1');
            }else if(!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(state.email)){
                state.emailError=true;
                state.errorMessage=payload.t('auth.register.email_error_message_2');
            }else if(state.email.length>=40){
                state.emailError=true;
                state.errorMessage=payload.t('auth.register.email_error_message_3');
                return;
            }else{
                state.emailError=false;
            }

            this.dispatch('authForms/sendRecoverPasswordEmail');
        },

        VALIDATE_NEW_PASSWORD_INPUTS(state,payload){
            
            if(state.password === ''){
                state.passwordError=true;
                state.passwordConfError=false;
                state.errorMessage=payload.t('auth.register.password_error_message_1');
                return;
            }else if(state.password.length<8){
                state.passwordError=true;
                state.passwordConfError=false;
                state.errorMessage=payload.t('auth.register.password_error_message_2');
                return;
            }else if(state.password.length>=25){
                state.passwordError=true;
                state.passwordConfError=false;
                state.errorMessage=payload.t('auth.register.password_error_message_3');
                return;
            }else{
                state.passwordError=false;

            }

            if(state.password != state.passwordConfirmation){
                state.passwordConfError=true;
                state.passwordError=false;
                state.errorMessage=payload.t('auth.register.password_conf_error_message_1');
                return;
            }else{
                state.passwordConfError=false;
            }

            this.dispatch('authForms/resetPassword',({ emailHash:payload.emailHash, userEmail: payload.userEmail }));

        },

        RESET_STATE(state){
            state.passwordShowing=false;
            state.passwordConfShowing=false;
            state.serverError=false;
            state.emailError=false;
            state.passwordError=false;
            state.nameError=false;
            state.lastNameError=false;
            state.passwordConfError=false;
            state.checkboxError=false;
            state.isLoading=false;
            state.firstName='';
            state.lastName='';
            state.email='';
            state.password='';
            state.passwordConfirmation='';
            state.checkboxAcceptance='';
            state.errorMessage='';

            if(localStorage.getItem('mainToken') && localStorage.getItem('mainToken') !== ''){
                localStorage.setItem('mainToken', '') 
            }

            if(localStorage.getItem('requestCache') && localStorage.getItem('requestCache') !== ''){
                localStorage.setItem('requestCache', Date.now()); 
            }

            if(localStorage.getItem('ordersCountCache') && localStorage.getItem('ordersCountCache') !== ''){
                localStorage.setItem('ordersCountCache', Date.now()); 
            }
        }
    
      },
      actions:{


        registerUser({ state }){
            state.isLoading=true;
            const userData = {
                first_name: state.firstName,
                last_name: state.lastName,
                email: state.email,
                password: state.password
            }
            this.$axios.post(`${process.env.VUE_APP_BASE_SERVER_URL}/${process.env.VUE_APP_API_VERSION}/auth/register`,userData).then(res=>{
                if(res.status === 200 && res.data.success){
                    state.serverError=false;
                    localStorage.setItem('token', res.data.data.authorization.token);
                    state.isLoading=false;
                    this.$router.push({ name: 'thank-you' })
                }
            }).catch( error => {
                state.serverError=true;
                state.isLoading=false;
                state.errorMessage=error.response.data.error.message;
            })
        },


        loginUser({ state }){
            state.isLoading=true;
            const userData = {
                email: state.email,
                password: state.password
            }
            this.$axios.post(`${process.env.VUE_APP_BASE_SERVER_URL}/${process.env.VUE_APP_API_VERSION}/auth/login`,userData).then( res => {
            if(res.status === 200 && res.data.success){
                if(res.data.data.user.terms_accepted_at === null){
                    state.serverError=false;
                    localStorage.setItem('token' , res.data.data.access_token);
                    state.isLoading=false;
                    this.$router.push({ name: 'user-terms-and-conditions' });
                    return;
                }
                if(res.data.data.terms_updated_at > res.data.data.user.terms_accepted_at){
                    state.serverError=false;
                    localStorage.setItem('token' , res.data.data.access_token);
                    state.isLoading=false;
                    this.$router.push({ name: 'user-terms-and-conditions' });
                    return;
                }
                if(res.data.data.user.email_verified_at){
                    state.serverError=false;
                    localStorage.setItem('token' , res.data.data.access_token);
                    state.isLoading=false;
                    this.$router.push({ name: 'summary' });

                }else{
                    localStorage.setItem('token', res.data.data.access_token);
                    this.$router.push({ name:'email-verification-required' });
                }  
            }
            }).catch( error => {
                if(error.status != 200){
                    state.serverError=true;
                    state.isLoading=false;
                    state.errorMessage=error.response.data.error.message;
                }
            } )
        },


        logoutUser(){
            this.$axios.post(`${process.env.VUE_APP_BASE_SERVER_URL}/${process.env.VUE_APP_API_VERSION}/auth/logout`, {}).then(res => {
                if (res.status === 200) {
                    localStorage.removeItem("token");
                    this.$router.push({ name: "login" });
                }
            });

        },


        sendRecoverPasswordEmail({state}){
            state.isLoading=true;
            const userEmail = {
                email: state.email
            }
            this.$axios.post(`${process.env.VUE_APP_BASE_SERVER_URL}/${process.env.VUE_APP_API_VERSION}/auth/reset-link`,userEmail).then(res => {
                if(res.status === 200){
                    state.isLoading=false;
                    this.$router.push({ name: 'recover-password-email' });
                }
            }).catch( error => {
                state.serverError=true;
                state.isLoading=false;
                state.errorMessage=error.response.data.error.message;
            } )
        },


        resetPassword({state},payload){
            state.isLoading=true;
            const newPasswordData = {
                password: state.password,
                email: payload.userEmail,
                token: payload.emailHash,
            }
            this.$axios.post(`${process.env.VUE_APP_BASE_SERVER_URL}/${process.env.VUE_APP_API_VERSION}/auth/reset-password`,newPasswordData).then(res => {
                if(res.status === 200){
                    state.isLoading=false;
                    this.$router.push({ name: 'login' });
                }
            }).catch(error =>{
                state.serverError=true;
                state.isLoading=false;
                state.errorMessage=error.response.data.error.message;
            })
          }


    }


  }