import axios from 'axios'
import router from '../router/index'

const $axios = axios.create();

$axios.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('token');

    if (token) {
      config.headers = {
        ...config.headers,
        authorization: `Bearer ${token}`,
      };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

$axios.interceptors.response.use(response => {
  return response;
}, error => {
  if(error.response.data.status === 401){
    localStorage.setItem('token', '');
    router.push('/login');
  }
  if (error.response.data.status === 403 && error.response.data.error.reason === 'USER_BANNED') {
      router.push('/banned');
  }
    return Promise.reject(error);
});

export  { $axios , axios };