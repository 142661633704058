import { createStore } from 'vuex'
import themeModule from './modules/theme.module.js'
import localeModule from './modules/locale.module.js'
import currencyModule from './modules/currency.module.js'
import userModule from './modules/user.module.js'
import authForms from './modules/authForms.module.js'
import dashboardModule from './modules/dashboardModule.js'
import categoryModule from './modules/category.module.js'
import roleModule from './modules/role.module.js'
import { $axios } from '@/httpHelper/axios-instances.js';
import router from '../router/index'

export const store = createStore({
  
  modules: {
    themeModule: themeModule,
    localeModule: localeModule,
    currencyModule: currencyModule,
    userModule: userModule,
    authForms: authForms,
    dashboardModule: dashboardModule,
    categoryModule: categoryModule,
    roleModule: roleModule
  }
  
});

store.$axios = $axios;
store.$router = router;